.homepage-layers-item {
   width: 100%;
   max-width: $desktop;
   margin: 0 auto;
   text-align: center;
   display: block;
   position: relative;
   border-bottom: 1px $darkblue solid;
   &.full-width {
      max-width: 100%;
      border-radius: 0;
   }
   &.padding-top {
      @extend %block-padding-top;
   }
   &.padding-bottom {
      @extend %block-padding-bottom;
   }
   & h1 {
      position: relative;
      display: inline-block;
      @extend %headline2;
      text-transform: uppercase;
      margin-bottom: 1.0rem;
      z-index: 1;
      text-align: center;
      background: $darkblue;
      padding: 0.35rem 2rem;
      font-weight: 400;
      color: $white;
      @include tablet {
        display: block;
         text-align: center;
         display: inline-block;
      }
   }
   & .description {
      display: block;
      font-size: 1.0rem;
      text-align: center;
      margin-bottom: 1.5rem;
      @include tablet-wide {
         font-size: 1.2rem;
         text-align: center;
      }
   }


   &-text {
      position: relative;
      z-index: 1;
      display: block;
      width: 100%;
      max-width: $desktop;
      margin: 0 auto;
      padding: 0 1.5rem;
      @include tablet {
         padding: 0;
      }
      &.contents_full_width {
         max-width: 100%;
         @include md {
            max-width: $desktop-medium;
         }
      }
   }

   &-grid {
      position: relative;
      z-index: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-content: flex-start;
      width: 100%;
      max-width: $desktop;
      margin: 0 auto;
      padding: 0 1.5rem;
      @include phone {
       
      }
      @include tablet {
         padding: 0 2rem;
      }
      @include md {
         padding: 0;
      }
      &.contents_full_width {
         max-width: 100%;
         padding: 0 2rem;
         @include md {
            max-width: $desktop-medium;
      	}
      }
   }
}


.layer-photo {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#edf7fe+10,daeefd+50 */
  background: rgb(237,247,254); /* Old browsers */
  background: -moz-linear-gradient(-45deg,  rgba(237,247,254,1) 10%, rgba(218,238,253,1) 50%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg,  rgba(237,247,254,1) 10%,rgba(218,238,253,1) 50%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg,  rgba(237,247,254,1) 10%,rgba(218,238,253,1) 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#edf7fe', endColorstr='#daeefd',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
		@include size(100%);	
		@include absolute(left 0 top 0);
		z-index: 0;
		&-pic {
		  opacity: .15;
		  mix-blend-mode: luminosity;
   			@include size(100%);	
   			@include absolute(left 0 top 0);
   			object-fit: cover;
   			overflow: hidden;	
      transition: $slow;
    @include tablet {
    }
  }
 	&-img {
 	  object-fit: contain;
    object-position: bottom;
 			@include size(100%);	
    transition: $veryslow;
    display: block;
    filter: grayscale(100%);
    @include tablet {
   			object-fit: cover;
    }
		}		
}
