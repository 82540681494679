.team-grid {
  margin: 2rem auto;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  @include tablet {
    grid-gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
  }
  @include desktop {
    grid-template-columns: repeat(4, 1fr);
  }
}

.services-grid {
  margin: 2rem auto;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
  @include tablet {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
  }
  @include desktop {
    grid-template-columns: repeat(3, 1fr);
  }
}
