#hero {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#009bdd+10,004379+90 */
  background: #004379;
  margin-top: 0 !important;
  position: relative;
  display: block;
  @includ tablet {
    margin-top: 3rem !important;
  }
  @include tablet-wide {
     margin-top: 3.75rem !important;
  }
  @include desktop {
     margin-top: 3.75rem !important;
  }
  &:after {
    content: "";
    width: 100%;
    height: .5rem;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 101;
    background: $blue;
  }
}

.hero-blurb {
		color: $text;
		text-align: center;
  max-width: $max-width;
		font-size: 0.9rem;
		line-height: 1.2;
		opacity: 1;	
  padding: 1rem 1rem .25rem;
  width: 100%;
  z-index: 100;
  position: absolute;
  bottom: 1rem;
  left: 0;
  text-align: center;
  @include phone {
    padding: 1rem 1rem .5rem;
  }
  @include tablet-wide {
 			font-size: 1.1rem;
    background: none;
    padding: 0 3rem !important;
    position: absolute;
    z-index: 100;
    bottom: 100px;
    left: 0;
    width: 100%;
    max-width: 100%;
  }
  @include desktop {
			 font-size: 1.2rem;
    padding: 0 10% !important;
		}		
		@include md {
			 font-size: 1.2rem;
    padding: 0 10% !important;
		}		
 	&-headline {
  		color: $white;
    font-family: $headline;
    font-weight: 400;
  		line-height: 1.5;
  		opacity: 1;
    position: relative;
    text-align: center;
    @extend %headline1;
    font-size: 0.75rem;
    text-shadow: none;
    text-transform: none;
    margin-bottom: 1rem;     
    @include phone {
       font-size: 0.9rem !important;
    }
    @include tablet {
       font-size: 1.0rem !important;
    }
    @include tablet-wide {
       font-size: 1.25rem !important;
    }
    @include desktop {
       font-size: 1.5rem !important;
    }
    @include md {
       width: 90%;
       margin: 0 auto 1rem;
       font-size: 1.9rem !important;
    }
 	}
}	

.hero-slider {
   display: block;
   overflow: hidden;
   @include size(100%, 460px);
   padding: 0;
   mix-blend-mode: soft-light;
   opacity: .8;
   @include phone {
     @include size(100%, 500px);
   }
   @include tablet {
     @include size(100%, 560px);
   }
   @include tablet-wide {
     @include size(100%, 640px);
   }
   @include desktop {
     @include size(100%, 750px);
   }
   @include md {
     @include size(100%, 800px);
   }
}

.slide {
  @include size(100%, 46px);	
  position: relative;
  margin: 0 auto;
  transition: $slow;
  overflow: hidden;
  @include phone {
    @include size(100%, 500px);
  }
  @include tablet {
    @include size(100%, 550px);	
    overflow: visible;
  }
  @include tablet-wide {
    @include size(100%, 640px);
  }
  @include desktop {
    @include size(100%, 750px);
  }
  @include md {
    @include size(100%, 800px);
  }
} 

.hero-item {
   &-container {
      width: 100%;
      position: relative;
      bottom: 0;
      left: 0;
      display: block;
      transition: $slow;
      @include tablet {
      }
      @include tablet-wide {
         position: absolute;
         bottom: 4rem !important;
         -webkit-box-shadow: none;
         box-shadow: none;          
         bottom: 5rem !important;
      }
      @include desktop {
         bottom: 6rem !important;
      }
   }
   &-grad {
      display: block;
      mix-blend-mode: color;
     	@include size(100%, 100%);	
    		@include absolute(left 0 top 0);
    		opacity: 1;
    		z-index: 2;
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00f0ff+25,00f0ff+75,00f0ff+75&0.4+25,0+75 */
      background: -moz-linear-gradient(-45deg,  rgba(0,240,255,0.4) 25%, rgba(0,240,255,0) 75%); /* FF3.6-15 */
      background: -webkit-linear-gradient(-45deg,  rgba(0,240,255,0.4) 25%,rgba(0,240,255,0) 75%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(135deg,  rgba(0,240,255,0.4) 25%,rgba(0,240,255,0) 75%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6600f0ff', endColorstr='#0000f0ff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      @include tablet-wide {
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00f0ff+25,00f0ff+75,00f0ff+75&0.4+25,0+75 */
        background: -moz-linear-gradient(-45deg,  rgba(0,240,255,0.4) 25%, rgba(0,240,255,0) 75%); /* FF3.6-15 */
        background: -webkit-linear-gradient(-45deg,  rgba(0,240,255,0.4) 25%,rgba(0,240,255,0) 75%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(135deg,  rgba(0,240,255,0.4) 25%,rgba(0,240,255,0) 75%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6600f0ff', endColorstr='#0000f0ff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      }
   }
   &-photo {
      width: 100%;
      height: 400px;
      top: 0px;
      left: 0px;
      position: relative;
      overflow: hidden;
      transition: $slow;
      @include phone {
         height: 400px;
      }
      @include tablet {
         height: 500px;
      }
      @include tablet-wide {
    		   @include size(100%, 100%);	
    		   @include absolute(left 0 top 0);
       		opacity: 1;
       		z-index: 1;
         overflow: visible;
      }
		&-pic {
      @include tablet {
      			@include size(100%);	
      			@include absolute(left 0 top 0);
      			object-fit: cover;
      			overflow: hidden;	
         transition: $slow;
         filter: contrast(1.65);
      }
   }
  	&-img {
   			@include size(100%);	
   			object-fit: cover;
      transition: $veryslow;
      display: block;
      filter: grayscale(100%);
 		}				
	}
   &:hover {
   }
   &:hover .hero-item-container {
      background: $black;
   }
   &:hover .hero-item-photo-pic {
      opacity: 1.0 !important;
   }
   &:hover .hero-item-photo-img {
   }
   &:first-child {
      height: 460px;
      @include phone {
        height: 500px;
     	}
      @include tablet {
        height: 550px;
     	}
      @include tablet-wide {
         height: 640px;
         -webkit-box-shadow: 0 0 40px 0 rgba(0,0,0,.85);
         box-shadow: 0 0 40px 0 rgba(0,0,0,.85); 
         z-index: 200;
      }
      @include desktop {
     		height: 750px;
     	}
      @include md {
        	height: 800px;
     	}
      @include lg {
         -webkit-box-shadow: none;
         box-shadow: none;          
      }
      &::after {
         @include tablet { 
         }
         @include lg {
         }
      } 
      & .hero-item-grad {
         display: block;
      }
      & .hero-item-photo {
      	@include size(100%, 100%);	
     		@include absolute(left 0 top 0);
     		opacity: 1;
     		z-index: 1;
         overflow: visible;
      }
      & .hero-item-container {
         position: absolute;
         bottom: 2rem;
      }
      & .hero-item-blurb {
         padding: 0 2rem;
      }
      & .hero-item-blurb-pre-headline {
         font-size: 1.0rem;
      }
      & .hero-item-blurb-headline {
         font-size: 2.0rem;
      }
   }
   &:nth-child(2) {
      padding: 0;
      @include tablet-wide {
         padding: 0;
         background: $clear;
      }
      & .hero-item-photo {
      }
      & .hero-item-container {
      }
      & .hero-item-photo-pic {
         opacity: 1.0;
      }
   }
   &:last-child {
      z-index: 100;
      @include tablet-wide {
         -webkit-box-shadow: none;
         box-shadow: none;          
      }
   }
}


/* -------- Slide Controls -------- */

.slide-controls {
	cursor: pointer;
	font-size: 1.8em;
	position: absolute; 
	top: 30%; 
	transform: scale(1.5);
	z-index: 101;
	color: #fff;
	opacity: 1;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition:    all .2s ease-in-out;
	-o-transition:      all .2s ease-in-out;
	-ms-transition:     all .2s ease-in-out;
	transition:         all .2s ease-in-out;   
	-moz-transform:    translateY(-50%);
	-ms-transform:     translateY(-50%);
	-webkit-transform: translateY(-50%);
	transform:         translateY(-50%); 
	@include tablet {
   font-size: 1.4em; 
   top: 50%; 
   opacity: .6;
	}
}  
.slide-controls:hover {
	opacity: 1;	
}
.prev-slide { left: 1em; fill: $white;}
.next-slide { right: 1em; fill: $white;}



